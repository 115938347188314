<template>
  <div class="main-content">
    <div class="layout-px-spacing mb-5">
      <div class="layout-top-spacing">
        <div class="layout-spacing">
          <div class="mb-3 d-md-flex justify-content-between">
            <h3>Viewing a Medical Request</h3>
            <div>
              <b-btn
                v-if="prescription"
                variant="dark"
                class="mr-1"
                @click="printDocument"
              >
                <i class="fa fa-print mr-1"></i>
                Print
              </b-btn>
              <b-btn
                v-if="
                  prescription &&
                  user.role == 'medical services' &&
                  prescription.closed_at == null
                "
                variant="info"
                :to="{
                  name: 'Edit Medical Request',
                  params: { id: prescription.id },
                }"
                class="mr-1"
              >
                <i class="fa fa-pen mr-1"></i>
                Edit
              </b-btn>
              <b-btn
                v-if="prescription && prescription.closed_at != null"
                variant="warning"
                class="mr-1"
                v-b-modal.replicate-medical-request
              >
                <i class="fa fa-copy mr-1"></i>
                Replicate
              </b-btn>
              <b-btn variant="primary" :to="{ name: 'All Medical Requests' }">
                <i class="fa fa-arrow-left mr-1"></i>
                Back
              </b-btn>
            </div>
          </div>

          <div class="p-2 text-center" v-if="!prescription">
            <b-card class="">
              <b-skeleton animation="wave" width="85%"></b-skeleton>
              <b-skeleton animation="wave" width="67%"></b-skeleton>
              <b-skeleton animation="wave" width="70%"></b-skeleton>
              <b-skeleton animation="wave" width="82%"></b-skeleton>
              <b-skeleton animation="wave" width="65%"></b-skeleton>
              <b-skeleton animation="wave" width="90%"></b-skeleton>
              <b-skeleton animation="wave" width="95%"></b-skeleton>
            </b-card>
          </div>

          <div
            id="prescriptionDocument"
            class="widget-content widget-content-area"
            v-else
          >
            <b-alert
              variant="primary"
              :show="prescription.paired_prescription ? true : false"
            >
              <i class="fas fa-exclamation-triangle"></i>
              This is a paired prescription!
            </b-alert>

            <b-alert
              variant="danger"
              :show="prescription.closed_at ? true : false"
            >
              <i class="fas fa-exclamation-triangle"></i>
              This prescription is closed
            </b-alert>

            <div class="table-responsive">
              <table class="table table-bordered">
                <tr>
                  <th width="15%">Patient</th>
                  <td
                    width="42.5%"
                    :colspan="prescription.paired_prescription ? '1' : '2'"
                  >
                    {{ prescription.elderly_name }}
                  </td>
                  <td width="42.5%" v-if="prescription.paired_prescription">
                    {{
                      prescription.paired_prescription
                        ? prescription.paired_prescription.elderly_name
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Patient Phone</th>
                  <td :colspan="prescription.paired_prescription ? '1' : '2'">
                    {{ prescription.elderly_phone }}
                  </td>
                  <td v-if="prescription.paired_prescription">
                    {{
                      prescription.paired_prescription
                        ? prescription.paired_prescription.elderly_phone
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Doctor Name</th>
                  <td colspan="2">{{ prescription.doctor_name }}</td>
                </tr>
                <tr>
                  <th>Doctor Phone</th>
                  <td colspan="2">{{ prescription.doctor_phone }}</td>
                </tr>
                <tr>
                  <th>Doctor Email</th>
                  <td colspan="2">{{ prescription.doctor_email }}</td>
                </tr>
                <tr>
                  <th>Doctor Comment</th>
                  <td colspan="2">{{ prescription.doctor_comment }}</td>
                </tr>
                <tr>
                  <th>Sponsor Name</th>
                  <td colspan="2">{{ prescription.sponsor_name }}</td>
                </tr>
                <tr>
                  <th>Sponsor Phone</th>
                  <td colspan="2">{{ prescription.sponsor_phone }}</td>
                </tr>
                <tr>
                  <th>Sponsor Email</th>
                  <td colspan="2">{{ prescription.sponsor_email }}</td>
                </tr>
                <tr>
                  <th>Sponsor Comment</th>
                  <td colspan="2">{{ prescription.sponsor_response }}</td>
                </tr>
                <tr>
                  <th>Update</th>
                  <td colspan="2">{{ prescription.update }}</td>
                </tr>
                <tr>
                  <th>Total</th>
                  <td :colspan="prescription.paired_prescription ? '1' : '2'">
                    {{ formatMoney(prescription.total) }}<br />
                    <small>{{
                      formatMoney(prescription.total_usd, "USD")
                    }}</small>
                  </td>
                  <td v-if="prescription.paired_prescription">
                    <span>
                      {{ formatMoney(prescription.paired_prescription.total)
                      }}<br />
                      <small>{{
                        formatMoney(
                          prescription.paired_prescription.total_usd,
                          "USD"
                        )
                      }}</small>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>Overall Total</th>
                  <td :colspan="prescription.paired_prescription ? '1' : '2'">
                    {{ formatMoney(prescription.overall_total) }}<br />
                    <small>{{
                      formatMoney(prescription.overall_total_usd, "USD")
                    }}</small>
                  </td>
                  <td v-if="prescription.paired_prescription">
                    <span>
                      {{
                        formatMoney(
                          prescription.paired_prescription.overall_total
                        )
                      }}<br />
                      <small>{{
                        formatMoney(
                          prescription.paired_prescription.overall_total_usd,
                          "USD"
                        )
                      }}</small>
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>Logistics Cost</th>
                  <td colspan="2">
                    {{ formatMoney(prescription.invoice.logistics_total) }}

                    <div class="mt-2">
                      <table class="table table-sm">
                        <thead>
                          <tr>
                            <th width="5%">S/N</th>
                            <th width="16%">Name</th>
                            <th width="15.5%">Description</th>
                            <th width="16%">GC Charges(%)</th>
                            <th width="16%">Extra</th>
                            <th width="16%">Amount</th>
                            <th width="16%">Vendor</th>
                            <th width="16%">Delivery Status</th>
                            <th width="16%">Payment Status</th>
                            <th with="16%">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              item, index
                            ) in prescription.invoice.items.filter(
                              (item) => item.is_logistics
                            )"
                            :key="item.id"
                          >
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.description }}</td>
                            <td>{{ item.vat }}</td>
                            <td>
                              {{
                                item.extra_amount
                                  ? formatMoney(item.extra_amount)
                                  : 0
                              }}
                            </td>
                            <td>{{ formatMoney(item.amount) }}</td>
                            <td>{{ item.vendor ? item.vendor.name : "" }}</td>
                            <td>
                              <span class="badge badge-info">{{
                                item.delivery_status
                                  ? item.delivery_status
                                  : "pending"
                              }}</span>
                            </td>
                            <td>
                              <span class="badge badge-info">{{
                                item.pay_status ? item.pay_status : "pending"
                              }}</span>
                            </td>
                            <td>
                               <b-dropdown
                                split
                                split-variant="outline-primary"
                                id="dropdown-left"
                                text="Action"
                                class="m-2"
                                size="sm"
                                variant="primary"
                              >
                                <b-dropdown-item
                                  @click="viewPrescriptionInvoiveItem(item)"
                                  >View</b-dropdown-item
                                >
                                <b-dropdown-item
                                  v-if="
                                    (user.role == 'medical services' || user.role == 'admin') 
                                    && item.delivery_status != 'delivered'
                                  "
                                  @click="
                                    updateInvoiceItemDeliveryStatus(
                                      item.id,
                                      'delivered'
                                    )
                                  "
                                  >Mark as delivered</b-dropdown-item
                                >
                                <b-dropdown-item
                                  v-if="
                                    (user.role == 'medical services' || user.role == 'admin') 
                                    && item.delivery_status != 'delivered'
                                  "
                                  @click="
                                    updateInvoiceItemDeliveryStatus(
                                      item.id,
                                      'not delivered'
                                    )
                                  "
                                  >Mark as not delivered</b-dropdown-item
                                >
                                <b-dropdown-item
                                  v-if="
                                    (user.role == 'medical services' || user.role == 'admin') 
                                    && item.pay_status != 'paid'
                                  "
                                  @click="payInvoiceItemVendor(item.id)"
                                  >Pay vendor</b-dropdown-item
                                >
                              </b-dropdown>
                              <b-modal
                                title="Invoice Item Details"
                                v-model="viewPrescriptionInvoiveItemModal"
                              >
                                <ViewInvoiceItemDetails
                                  :invoiceItem="selectedInvoiceItem"
                                />
                                <template #modal-footer>
                                  <div class="w-100">
                                    <b-button
                                      variant="primary"
                                      size="sm"
                                      class="float-right"
                                      @click="
                                        viewPrescriptionInvoiveItemModal = false
                                      "
                                    >
                                      Close
                                    </b-button>
                                  </div>
                                </template>
                              </b-modal>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Overall Logistics Cost</th>
                  <td colspan="2">
                    {{
                      formatMoney(prescription.invoice.overall_logistics_total)
                    }}
                  </td>
                </tr>
                <tr class="table-primary">
                  <th>Amount Payable</th>
                  <td colspan="2">
                    <div class="d-flex justify-content-between">
                      <div>
                        <span v-if="prescription.paired_prescription">
                          <p class="small">
                            Formula: Overall Total for both patients - Logistics
                            Total
                          </p>
                          <b>
                            {{
                              formatMoney(
                                prescription.overall_total +
                                  prescription.paired_prescription
                                    .overall_total -
                                  prescription.invoice.overall_logistics_total
                              )
                            }}
                          </b>
                        </span>
                        <span v-else>
                          <p class="small">Formula: Overall Total</p>
                          <b>{{ formatMoney(prescription.overall_total) }}</b>
                        </span>
                      </div>
                      <div v-if="prescription.use_wallet || prescription.paid_from_wallet">
                        <span>
                          <p class="small">Payable via Wallet</p>
                          <b>{{ prescription.use_wallet ? "YES" : "NO" }}</b>
                        </span>
                      </div>
                      <div v-if="prescription.use_wallet || prescription.paid_from_wallet">
                        <span>
                          <p class="small">Amount Paid From Wallet</p>
                          <b>{{
                            formatMoney(prescription.amount_paid_from_wallet)
                          }}</b>
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Invoice</th>
                  <td :colspan="prescription.paired_prescription ? '1' : '2'">
                    <a :href="prescription.invoice.filename" target="_blank">
                      <i class="fa fa-file mr-1"></i>
                      {{ prescription.invoice.invoice_no }}
                      <sup><i class="fa fa-external-link-alt"></i></sup>
                    </a>

                    <div class="mt-2">
                      <table class="table table-sm">
                        <thead>
                          <tr>
                            <th width="5%">S/N</th>
                            <th width="16%">Name</th>
                            <th width="16%">Description</th>
                            <th width="16">Amount</th>
                            <th width="15%">Vendor</th>
                            <th width="16%">Delivery Status</th>
                            <th width="16%">Payment Status</th>
                            <th width="16%">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              item, index
                            ) in prescription.invoice.items.filter(
                              (item) => !item.is_logistics
                            )"
                            :key="item.id"
                          >
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.description }}</td>
                            <td>{{ formatMoney(item.amount) }}</td>
                            <td>{{ item.vendor ? item.vendor.name : "" }}</td>
                            <td>
                              <span class="badge badge-info">{{
                                item.delivery_status
                                  ? item.delivery_status
                                  : "pending"
                              }}</span>
                            </td>
                            <td>
                              <span class="badge badge-info">{{
                                item.pay_status ? item.pay_status : "pending"
                              }}</span>
                            </td>
                            <td>
                              <b-dropdown
                                split
                                split-variant="outline-primary"
                                id="dropdown-left"
                                text="Action"
                                class="m-2"
                                size="sm"
                                variant="primary"
                              >
                                <b-dropdown-item
                                  @click="viewPrescriptionInvoiveItem(item)"
                                  >View</b-dropdown-item
                                >
                                <b-dropdown-item
                                  v-if="
                                    (user.role == 'medical services' || user.role == 'admin')
                                    && item.delivery_status != 'delivered'
                                  "
                                  @click="
                                    updateInvoiceItemDeliveryStatus(
                                      item.id,
                                      'delivered'
                                    )
                                  "
                                  >Mark as delivered</b-dropdown-item
                                >
                                <b-dropdown-item
                                  v-if="
                                    (user.role == 'medical services' || user.role == 'admin')
                                    && item.delivery_status != 'delivered'
                                  "
                                  @click="
                                    updateInvoiceItemDeliveryStatus(
                                      item.id,
                                      'not delivered'
                                    )
                                  "
                                  >Mark as not delivered</b-dropdown-item
                                >
                                <b-dropdown-item
                                  v-if="
                                    (user.role == 'medical services' || user.role == 'admin')
                                    && item.pay_status != 'paid'
                                  "
                                  @click="payInvoiceItemVendor(item.id)"
                                  >Pay vendor</b-dropdown-item
                                >
                              </b-dropdown>
                              <b-modal
                                title="Invoice Item Details"
                                v-model="viewPrescriptionInvoiveItemModal"
                              >
                                <ViewInvoiceItemDetails
                                  :invoiceItem="selectedInvoiceItem"
                                />
                                <template #modal-footer>
                                  <div class="w-100">
                                    <b-button
                                      variant="primary"
                                      size="sm"
                                      class="float-right"
                                      @click="
                                        viewPrescriptionInvoiveItemModal = false
                                      "
                                    >
                                      Close
                                    </b-button>
                                  </div>
                                </template>
                              </b-modal>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                  <td v-if="prescription.paired_prescription">
                    <a
                      :href="prescription.paired_prescription.invoice.filename"
                      target="_blank"
                    >
                      <i class="fa fa-file mr-1"></i>
                      {{ prescription.paired_prescription.invoice.invoice_no }}
                      <sup><i class="fa fa-external-link-alt"></i></sup>
                    </a>

                    <div class="mt-2">
                      <table class="table table-sm">
                        <thead>
                          <tr>
                            <th width="5%">S/N</th>
                            <th width="16%">Name</th>
                            <th width="15.5%">Description</th>
                            <!-- <th width="16%">GC Charges(%)</th> -->
                            <th width="16%">Amount</th>
                            <th width="16%">Vendor</th>
                            <th width="16%">Delivery Status</th>
                            <th with="16%">Payment Status</th>
                            <th width="16%">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              item, index
                            ) in prescription.paired_prescription.invoice.items.filter(
                              (item) => !item.is_logistics
                            )"
                            :key="item.id"
                          >
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.description }}</td>
                            <td>{{ formatMoney(item.amount) }}</td>
                            <td>{{ item.vendor ? item.vendor.name : "" }}</td>
                             <td>
                              <span class="badge badge-info">{{
                                item.delivery_status
                                  ? item.delivery_status
                                  : "pending"
                              }}</span>
                            </td>
                            <td>
                              <span class="badge badge-info">{{
                                item.pay_status ? item.pay_status : "pending"
                              }}</span>
                            </td>
                            <td>
                               <b-dropdown
                                split
                                split-variant="outline-primary"
                                id="dropdown-left"
                                text="Action"
                                class="m-2"
                                size="sm"
                                variant="primary"
                              >
                                <b-dropdown-item
                                  @click="viewPrescriptionInvoiveItem(item)"
                                  >View</b-dropdown-item
                                >
                                <b-dropdown-item
                                  v-if="
                                    user.role == 'medical services' &&
                                    item.delivery_status != 'delivered'
                                  "
                                  @click="
                                    updateInvoiceItemDeliveryStatus(
                                      item.id,
                                      'delivered'
                                    )
                                  "
                                  >Mark as delivered</b-dropdown-item
                                >
                                <b-dropdown-item
                                  v-if="
                                    user.role == 'medical services' &&
                                    item.delivery_status != 'delivered'
                                  "
                                  @click="
                                    updateInvoiceItemDeliveryStatus(
                                      item.id,
                                      'not delivered'
                                    )
                                  "
                                  >Mark as not delivered</b-dropdown-item
                                >
                                <b-dropdown-item
                                  v-if="
                                    user.role == 'medical services' &&
                                    item.pay_status != 'paid'
                                  "
                                  @click="payInvoiceItemVendor(item.id)"
                                  >Pay vendor</b-dropdown-item
                                >
                              </b-dropdown>
                              <b-modal
                                title="Invoice Item Details"
                                v-model="viewPrescriptionInvoiveItemModal"
                              >
                                <ViewInvoiceItemDetails
                                  :invoiceItem="selectedInvoiceItem"
                                />
                                <template #modal-footer>
                                  <div class="w-100">
                                    <b-button
                                      variant="primary"
                                      size="sm"
                                      class="float-right"
                                      @click="
                                        viewPrescriptionInvoiveItemModal = false
                                      "
                                    >
                                      Close
                                    </b-button>
                                  </div>
                                </template>
                              </b-modal>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Created Date</th>
                  <td colspan="2">
                    {{ formatFancyDateFull(prescription.created_at) }}
                  </td>
                </tr>
                <tr>
                  <th>Last Modified Date</th>
                  <td colspan="2">
                    {{ formatFancyDateFull(prescription.updated_at) }}
                  </td>
                </tr>
                <tr>
                  <th>Closed Date</th>
                  <td colspan="2">
                    {{
                      prescription.closed_at
                        ? formatFancyDateFull(prescription.closed_at)
                        : "nil"
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Pay Status</th>
                  <td
                    :colspan="prescription.paired_prescription ? '1' : '2'"
                    :class="{
                      'table-danger': prescription.pay_status == 'not paid',
                      'table-warning':
                        prescription.pay_status == 'paid not confirmed',
                      'table-success': prescription.pay_status == 'paid',
                    }"
                  >
                    <div v-if="user.role == 'accounts' || user.role == 'admin'">
                      <span
                        v-if="
                          prescription.pay_status == 'paid' ||
                          prescription.closed_at != null
                        "
                      >
                        {{ prescription.pay_status }}
                      </span>
                      <span v-else>
                        <label
                          class="new-control new-radio new-radio-text radio-success"
                          v-if="
                            prescription.pay_status == 'paid not confirmed' ||
                            prescription.pay_status == 'not paid'
                          "
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            value="paid"
                            v-model="prescriptionUpdate.pay_status"
                          />
                          <span class="new-control-indicator"></span>
                          <span class="new-radio-content">Paid</span>
                        </label>
                        <label
                          class="new-control new-radio new-radio-text radio-warning"
                          v-if="
                            prescription.pay_status == 'paid not confirmed' ||
                            prescription.pay_status == 'not paid'
                          "
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            value="paid not confirmed"
                            v-model="prescriptionUpdate.pay_status"
                          />
                          <span class="new-control-indicator"></span>
                          <span class="new-radio-content"
                            >Paid Not Confirmed</span
                          >
                        </label>
                        <label
                          class="new-control new-radio new-radio-text radio-danger"
                          v-if="prescription.pay_status == 'not paid'"
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            value="not paid"
                            v-model="prescriptionUpdate.pay_status"
                          />
                          <span class="new-control-indicator"></span>
                          <span class="new-radio-content">Not Paid</span>
                        </label>
                        <b-btn
                          v-if="prescription.pay_status != 'paid'"
                          size="sm"
                          @click="updatePayStatus({ isPair: false })"
                          :disabled="loading"
                        >
                          <b-spinner
                            label="Loading"
                            v-if="loading"
                            small
                          ></b-spinner>
                          Update Pay Status
                        </b-btn>
                        <b-btn
                          v-if="prescription.pay_status != 'paid'"
                          size="sm"
                          @click="payFromWallet({ isPair: false })"
                          :disabled="loading"
                        >
                          <b-spinner
                            label="Loading"
                            v-if="loading"
                            small
                          ></b-spinner>
                          <i class="fa fa-wallet"></i>
                          Pay from Wallet
                        </b-btn>
                      </span>
                    </div>
                    <div v-else>
                      {{ prescription.pay_status }}
                    </div>
                  </td>
                  <td
                    v-if="prescription.paired_prescription"
                    :class="{
                      'table-danger':
                        prescription.paired_prescription.pay_status ==
                        'not paid',
                      'table-warning':
                        prescription.paired_prescription.pay_status ==
                        'paid not confirmed',
                      'table-success':
                        prescription.paired_prescription.pay_status == 'paid',
                    }"
                  >
                    <div v-if="user.role == 'accounts' || user.role == 'admin'">
                      <span
                        v-if="
                          prescription.paired_prescription.pay_status ==
                            'paid' ||
                          prescription.paired_prescription.closed_at != null
                        "
                      >
                        {{ prescription.paired_prescription.pay_status }}
                      </span>
                      <span v-else>
                        <label
                          class="new-control new-radio new-radio-text radio-success"
                          v-if="
                            prescription.paired_prescription.pay_status ==
                              'paid' ||
                            prescription.paired_prescription.pay_status ==
                              'paid not confirmed' ||
                            prescription.paired_prescription.pay_status ==
                              'not paid'
                          "
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            value="paid"
                            v-model="
                              prescriptionUpdate.paired_prescription.pay_status
                            "
                          />
                          <span class="new-control-indicator"></span>
                          <span class="new-radio-content">Paid</span>
                        </label>
                        <label
                          class="new-control new-radio new-radio-text radio-warning"
                          v-if="
                            prescription.paired_prescription.pay_status ==
                              'paid not confirmed' ||
                            prescription.paired_prescription.pay_status ==
                              'not paid'
                          "
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            value="paid not confirmed"
                            v-model="
                              prescriptionUpdate.paired_prescription.pay_status
                            "
                          />
                          <span class="new-control-indicator"></span>
                          <span class="new-radio-content"
                            >Paid Not Confirmed</span
                          >
                        </label>
                        <label
                          class="new-control new-radio new-radio-text radio-danger"
                          v-if="
                            prescription.paired_prescription.pay_status ==
                            'not paid'
                          "
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            value="not paid"
                            v-model="
                              prescriptionUpdate.paired_prescription.pay_status
                            "
                          />
                          <span class="new-control-indicator"></span>
                          <span class="new-radio-content">Not Paid</span>
                        </label>
                        <b-btn
                          v-if="
                            prescription.paired_prescription.pay_status !=
                            'paid'
                          "
                          size="sm"
                          @click="updatePayStatus({ isPair: true })"
                          :disabled="loading"
                        >
                          <b-spinner
                            label="Loading"
                            v-if="loading"
                            small
                          ></b-spinner>
                          Update Pay Status
                        </b-btn>
                      </span>
                    </div>
                    <div v-else>
                      {{ prescription.pay_status }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Date Paid</th>
                  <td :colspan="prescription.paired_prescription ? '1' : '2'">
                    {{
                      prescription.date_paid
                        ? formatFancyDateFull(prescription.date_paid)
                        : ""
                    }}
                  </td>
                  <td v-if="prescription.paired_prescription">
                    {{
                      prescription.paired_prescription.date_paid
                        ? formatFancyDateFull(
                            prescription.paired_prescription.date_paid
                          )
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Delivery Status</th>
                  <td
                    colspan="2"
                    :class="{
                      'table-danger':
                        prescription.delivery_status == 'not delivered',
                      'table-success':
                        prescription.delivery_status == 'delivered',
                    }"
                  >
                    <div v-if="user.role == 'medical services'">
                      <span
                        v-if="
                          prescription.delivery_status == 'delivered' ||
                          prescription.closed_at != null
                        "
                      >
                        {{ prescription.delivery_status }}
                      </span>
                      <span v-else>
                        <label
                          class="new-control new-radio new-radio-text radio-success"
                          v-if="
                            prescription.delivery_status == 'not delivered' ||
                            prescription.delivery_status == 'delivered'
                          "
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            value="delivered"
                            v-model="prescriptionUpdate.delivery_status"
                          />
                          <span class="new-control-indicator"></span>
                          <span class="new-radio-content">Delivered</span>
                        </label>
                        <label
                          class="new-control new-radio new-radio-text radio-danger"
                          v-if="prescription.delivery_status == 'not delivered'"
                        >
                          <input
                            type="radio"
                            class="new-control-input"
                            value="not delivered"
                            v-model="prescriptionUpdate.delivery_status"
                          />
                          <span class="new-control-indicator"></span>
                          <span class="new-radio-content">Not Delivered</span>
                        </label>
                        <button
                          size="sm"
                          class="btn btn-sm btn-primary"
                          v-if="prescription.delivery_status != 'delivered'"
                          @click="updateDeliveryStatus()"
                          :disabled="loading"
                        >
                          <b-spinner
                            label="Loading"
                            v-if="loading"
                            small
                          ></b-spinner>
                          Update Delivery Status
                        </button>
                      </span>
                    </div>
                    <div v-else>
                      {{ prescription.delivery_status }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Date Delivered</th>
                  <td colspan="2">
                    {{
                      prescription.date_delivered
                        ? formatFancyDateFull(prescription.date_delivered)
                        : ""
                    }}
                  </td>
                </tr>
                <tr>
                  <th>Pay Vendors Status</th>
                  <td
                    colspan="2"
                    :class="{
                      'table-success': prescription.pay_vendors == true,
                      'table-danger': prescription.pay_vendors == false,
                    }"
                  >
                    <div v-if="user.role == 'medical services'">
                      <span
                        v-if="
                          prescription.pay_vendors ||
                          prescription.closed_at != null
                        "
                      >
                        {{ prescription.pay_vendors ? "yes" : "no" }}
                      </span>
                      <span v-else>
                        <button
                          size="sm"
                          class="btn btn-sm btn-primary"
                          @click="payVendors()"
                          :disabled="loading"
                        >
                          <b-spinner
                            label="Loading"
                            v-if="loading"
                            small
                          ></b-spinner>
                          Pay Vendors
                        </button>
                      </span>
                    </div>
                    <div v-else>
                      {{ prescription.pay_vendors ? "yes" : "no" }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>Vendors Paid Date</th>
                  <td colspan="2">
                    {{
                      prescription.paid_vendors_at
                        ? formatFancyDateFull(prescription.paid_vendors_at)
                        : ""
                    }}
                  </td>
                </tr>
              </table>
            </div>

            <b-modal
              id="replicate-medical-request"
              title="Replicate Medical Request"
              centered
              hide-footer
            >
              <AlertError :form="replicaForm" />

              <h5>
                Are you sure you want to create a replica of this request?
              </h5>

              <form
                method="POST"
                @submit.prevent="replicateRequest()"
                @keydown="replicaForm.onKeydown($event)"
              >
                <b-form-checkbox
                  v-model="replicaForm.paired"
                  v-if="prescription.paired_prescription"
                  size="lg"
                  switch
                >
                  Replicate the paired prescription too?
                </b-form-checkbox>

                <b-btn
                  variant="primary"
                  type="submit"
                  :disabled="replicaForm.busy"
                  size="sm"
                  class="mt-3"
                  block
                >
                  <b-spinner
                    label="Loading"
                    v-if="replicaForm.busy"
                    small
                  ></b-spinner>
                  Replicate
                </b-btn>
              </form>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { endpoints } from "@/utils/endpoints";
import { http } from "@/utils/http";
import { mapGetters } from "vuex";
import _ from "lodash";
import "@/assets/js/printThis";
import $ from "jquery";
import ViewInvoiceItemDetails from "./ViewInvoiceItemDetails.vue";

export default {
  name: "ViewRequest",
  props: ["id"],
  components: { ViewInvoiceItemDetails },
  data() {
    return {
      loading: false,
      prescription: null,
      prescriptionUpdate: null, // for updating the prescription (v-models)
      replicaForm: new this.$Form({
        paired: false,
      }),
      viewPrescriptionInvoiveItemModal: false,
      selectedInvoiceItem: null,
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    // amountPayable() {
    //   if (this.prescription) {
    //     return (this.prescription.overall_total + )
    //   }
    //   return this.prescription.prescription.overall_total +
    //     prescription.paired_prescription.overall_total -
    //                               prescription.invoice.overall_logistics_total
    // }
  },
  methods: {
    viewPrescriptionInvoiveItem(invoiceItem) {
      this.selectedInvoiceItem = invoiceItem;
      this.viewPrescriptionInvoiveItemModal = true;
    },
    fetchRecord(invoiceItemId = null) {
      let route = endpoints.GET_SINGLE_PRESCRIPTION_RECORD.replace(
        ":id",
        this.id ? this.id : ""
      );

      http
        .get(route + (invoiceItemId ? `?invoice_item_id=${invoiceItemId}` : ""))
        .then((response) => {
          this.prescription = response;

          this.prescriptionUpdate = _.cloneDeep(this.prescription);
        })
        .catch((error) => {
          this.$toast.error(
            error.response?.data?.message || error.response?.message
          );
        });
    },
    updatePayStatus({ isPair = false }) {
      this.loading = true;

      http
        .patch(
          endpoints.UPDATE_PRESCRIPTION_PAY_STATUS.replace(
            ":id",
            isPair
              ? this.prescriptionUpdate.paired_prescription.id
              : this.prescriptionUpdate.id
          ),
          {
            pay_status: isPair
              ? this.prescriptionUpdate.paired_prescription.pay_status
              : this.prescriptionUpdate.pay_status,
          }
        )
        .then((response) => {
          this.$toast.success(response);
          this.fetchRecord();
        })
        .catch((error) => {
          this.$toast.error(error.response?.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateDeliveryStatus() {
      this.loading = true;

      http
        .patch(
          endpoints.UPDATE_PRESCRIPTION_DELIVERY_STATUS.replace(
            ":id",
            this.prescription.id
          ),
          {
            delivery_status: this.prescriptionUpdate.delivery_status,
          }
        )
        .then((response) => {
          this.$toast.success(response);
          this.fetchRecord();
        })
        .catch((error) => {
          if (error?.response?.data) {
            this.$toast.error(error.response.data);
          } else {
            this.$toast.error(error.response?.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    updateInvoiceItemDeliveryStatus(invoiceItemId, status) {
      this.loading = true;

      http
        .patch(
          endpoints.UPDATE_INVOICE_ITEM_PRESCRIPTION_DELIVERY_STATUS.replace(
            ":id",
            this.prescription.id
          ),
          {
            delivery_status: status,
            invoiceItemIds: [invoiceItemId],
          }
        )
        .then((response) => {
          this.$toast.success(response);
          this.fetchRecord();
        })
        .catch((error) => {
          if (error?.response?.data) {
            this.$toast.error(error.response.data);
          } else {
            this.$toast.error(error.response?.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    payFromWallet(){
      this.loading = true;
      http
        .post(endpoints.PAY_PRESCRIPTION_FROM_WALLET.replace(
            ":id",
            this.prescription.id
          ),
          {
            'prescription_id': this.prescription.id
          }
        )
        .then((response) => {
          this.$toast.success(response)
          this.fetchRecord();
        })
        .catch((error) => {
          if (error?.response?.data) {
            this.$toast.error(error.response.data);
          } else {
            this.$toast.error(error.response?.message);
          }
        })
        .finally(() => {
          this.loading = false;
        })
    },

    payVendors() {
      this.loading = true;

      http
        .patch(
          endpoints.PAY_PRESCRIPTION_VENDORS.replace(
            ":id",
            this.prescription.id
          )
        )
        .then((response) => {
          this.$toast.success(response);
          this.fetchRecord();
        })
        .catch((error) => {
          if (error?.response?.data) {
            this.$toast.error(error.response.data);
          } else {
            this.$toast.error(error.response?.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    payInvoiceItemVendor(invoiceItemId) {
      this.loading = true;

      http
        .patch(
          endpoints.PAY_INVOICE_ITEM_VENDOR.replace(
            ":id",
            this.prescription.id
          ),
          {
            invoiceItemIds: [invoiceItemId],
          }
        )
        .then((response) => {
          this.$toast.success(response);
          this.fetchRecord();
        })
        .catch((error) => {
          if (error?.response?.data) {
            this.$toast.error(error.response.data);
          } else {
            this.$toast.error(error.response?.message);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    printDocument() {
      $("#prescriptionDocument").printThis({
        debug: false,
        importCSS: true,
        importStyle: true,
        printContainer: true,
        loadCSS: "",
        pageTitle:
          `Prescription for ${this.prescription.elderly_name}` +
          (this.prescription.paired_prescription
            ? ` and ${this.prescription.paired_prescription.elderly_name}`
            : ""),
        removeInline: false,
        removeInlineSelector: "*",
        printDelay: 300,
        header: null,
        footer: null,
        base: false,
        formValues: true,
        canvas: false,
        doctypeString: "...",
        removeScripts: false,
        copyTagClasses: false,
        beforePrintEvent: null,
        beforePrint: null,
        afterPrint: null,
      });
    },
    replicateRequest() {
      this.replicaForm
        .patch(
          endpoints.REPLICATE_PRESCRIPTION.replace(":id", this.prescription.id)
        )
        .then((response) => {
          this.$toast.success("Replication successful");
          this.$bvModal.hide("replicate-medical-request");
          this.$router.push({
            name: "Edit Medical Request",
            params: { id: response.id },
          });
        })
        .catch((error) => {
          this.$toast.error(
            error?.response?.data?.message || error?.response?.message
          );
        });
    },
  },
  mounted() {
    if (this.id) {
      this.fetchRecord();
    }

    if (this.$route.query.invoiceitem) {
      this.fetchRecord(this.$route.query.invoiceitem);
    }
  },
};
</script>
