<template>
  <div>
    <div class="mb-2">
      <b>Name:</b> <span>{{ invoiceItem.name }}</span>
    </div>
    <div class="mb-2">
      <b>Description:</b> <span>{{ invoiceItem.description }}</span>
    </div>
    <div class="mb-2">
      <b>GC Charges(%):</b> <span>{{ invoiceItem.vat }}</span>
    </div>
    <div class="mb-2">
      <b>Amount:</b> <span>{{ formatMoney(invoiceItem.amount) }}</span>
    </div>
    <div class="mb-2">
      <b>Extra:</b
      ><span>{{
        invoiceItem.extra_amount ? formatMoney(invoiceItem.extra_amount) : 0
      }}</span>
    </div>
    <div class="mb-2">
      <b>Vendor:</b>
      <span>{{ invoiceItem.vendor ? invoiceItem.vendor.name : "" }}</span>
    </div>
    <div class="mb-2">
      <b>Delivery Status: </b>
      <span class="badge badge-info">{{
        invoiceItem.delivery_status ? invoiceItem.delivery_status : "pending"
      }}</span>
    </div>
    <div class="mb-2" v-if="invoiceItem.delivery_status">
      <b>Date Delivered: </b> <span>{{ invoiceItem.date_delivered }}</span>
    </div>
    <div class="mb-2">
      <b>Payment Status: </b>
      <span class="badge badge-info">{{
        invoiceItem.pay_status ? invoiceItem.pay_status : "pending"
      }}</span>
    </div>
    <div class="mb-2" v-if="invoiceItem.pay_status">
      <b>Date Paid: </b> <span>{{ invoiceItem.date_paid }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    invoiceItem: {
      type: Object,
      default: () => null,
    },
  },
};
</script>

<style></style>
